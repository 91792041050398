import { tokens } from "../tokens";

export const it = {
  [tokens.common.languageChanged]: "Lingua modificata",
  [tokens.nav.aboutUs]: "Chi siamo",
  [tokens.nav.academy]: "Accademia",
  [tokens.nav.account]: "Account",
  [tokens.nav.affiliate]: "Affiliati",
  [tokens.nav.agents]: "Agenti",
  [tokens.nav.analytics]: "Analisi",
  [tokens.nav.article]: "FAQ e Articoli",
  [tokens.nav.supportChats]: "Chat di supporto",
  [tokens.nav.emails]: "Email",
  [tokens.nav.auth]: "Autenticazione",
  [tokens.nav.bankProvider]: "Fornitore bancario",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Marchi",
  [tokens.nav.browse]: "Sfoglia",
  [tokens.nav.calendar]: "Calendario",
  [tokens.nav.logs]: "Registri",
  [tokens.nav.call]: "Sistema di chiamate",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Checkout",
  [tokens.nav.concepts]: "Concetti",
  [tokens.nav.contact]: "Contatto",
  [tokens.nav.contactUs]: "Contattaci",
  [tokens.nav.orderNow]: "Ordina ora",
  [tokens.nav.course]: "Corso",
  [tokens.nav.create]: "Crea",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customerCare]: "Assistenza clienti",
  [tokens.nav.customers]: "Clienti",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.dataEntry]: "Inserimento dati",
  [tokens.nav.demo]: "DEMO",
  [tokens.nav.details]: "Dettagli",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Modifica",
  [tokens.nav.error]: "Errore",
  [tokens.nav.feed]: "Feed",
  [tokens.nav.fileManager]: "Gestione file",
  [tokens.nav.finance]: "Finanza",
  [tokens.nav.fleet]: "Flotta",
  [tokens.nav.forgotPassword]: "Password dimenticata",
  [tokens.nav.home]: "Home",
  [tokens.nav.injection]: "Lista iniezioni",
  [tokens.nav.integration]: "Integrazione",
  [tokens.nav.internalChat]: "Chat interna",
  [tokens.nav.invoiceList]: "Fatture",
  [tokens.nav.jobList]: "Offerte di lavoro",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.lawFirms]: "Studi legali",
  [tokens.nav.lead]: "Gestione lead",
  [tokens.nav.leads]: "Lead",
  [tokens.nav.list]: "Lista",
  [tokens.nav.login]: "Accedi",
  [tokens.nav.logistics]: "Logistica",
  [tokens.nav.mail]: "Posta",
  [tokens.nav.management]: "Gestione",
  [tokens.nav.marketing]: "Agenzie di marketing",
  [tokens.nav.merchant]: "Commissioni e tariffe",
  [tokens.nav.offers]: "Offerte",
  [tokens.nav.orderList]: "Ordini",
  [tokens.nav.overview]: "Panoramica",
  [tokens.nav.pages]: "Pagine",
  [tokens.nav.paymentService]: "Fornitori di servizi di pagamento",
  [tokens.nav.paymentType]: "Tipo di pagamento",
  [tokens.nav.payment]: "Verifica pagamenti",
  [tokens.nav.positions]: "Posizioni",
  [tokens.nav.postCreate]: "Crea post",
  [tokens.nav.postDetails]: "Dettagli post",
  [tokens.nav.postList]: "Lista post",
  [tokens.nav.pricing]: "Prezzi",
  [tokens.nav.productList]: "Prodotti",
  [tokens.nav.products]: "Prodotti",
  [tokens.nav.profile]: "Profilo",
  [tokens.nav.record]: "Registrazioni",
  [tokens.nav.register]: "Registrati",
  [tokens.nav.reports]: "Rapporti",
  [tokens.nav.resetPassword]: "Reimposta password",
  [tokens.nav.risk]: "Gestione rischi",
  [tokens.nav.leaderboard]: "Classifica",
  [tokens.nav.settings]: "Impostazioni",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.title]: "Titolo",
  [tokens.nav.transactions]: "Transazioni",
  [tokens.nav.walletTransactions]: "Transazioni portafoglio",
  [tokens.nav.validationRules]: "Attività di validazione",
  [tokens.nav.verifyCode]: "Verifica codice",
  [tokens.nav.wallets]: "Portafogli",
  [tokens.nav.complianceRegulationAudit]: "Conformità",
  [tokens.nav.compliance]: "Conformità",
  [tokens.nav.agentReport]: "Rapporto agente",
  [tokens.nav.clientReport]: "Rapporto cliente",
  [tokens.nav.regulartoryReport]: "Rapporto normativo",
};